<template>
	<div class="section-wrapper">
		<base-container>
			<base-row>
				<cms-text :value="`${prefix}.content`" :props="{size: 'xs'}"/>
			</base-row>
		</base-container>
	</div>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
